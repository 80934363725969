import * as React from "react"
/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { MenuIcon, XIcon, InboxIcon, SparklesIcon } from '@heroicons/react/outline'
import { Helmet } from 'react-helmet'
import Layout from "../components/layout"
import GetInTouch from '../components/kontakt/contactform'
import Head from '../components/referenzen/head'



export default function Refs() {
  return (
    <Layout>
        <Helmet title="Kontakt - Wuppertal360 - 360° Panoramen und virtuelle Rundgänge" />
        <GetInTouch />


    </Layout>

  )
}
